@use '../../paletteColors'
@use '../../extra_fonts'

$space_from_previous_area: 200px
$space_from_previous_area_sm: 80px

#container
    margin: 0
    padding: 0
    padding-bottom: 50px
    background-color: #fff

#SE-publi
    margin: 0
    background-repeat: no-repeat
    background-size: cover
    background-position-y: 15%
    box-shadow: 0px 0px 2px 2px rgba(#000, 0.5)

    .inner
        background-color: rgba(#732222, 0.9)
        background-color: rgba(#000, 0.5)
        width: fit-content
        height: fit-content
        max-width: 60%
        color: #fff
        padding-left: 1.5rem
        padding-top: 5%
        padding-bottom: 5%
        font-size: 1.5rem

        strong
            font-size: 2.8rem
        
        .button
            font-size: 1.1rem
            background-color: #fff
            width: fit-content
            color: #000
            padding: 0.8rem 0.6rem
            border-radius: 20px
            font-weight: bold

.button-row
    justify-content: center
    margin: 0
    margin-top: $space_from_previous_area_sm

    .button
        font-size: 1.5rem
        background-color: paletteColors.$main-blue
        width: fit-content
        color: #fff
        padding: 0.8rem 0.6rem
        border-radius: 20px
        font-weight: bold

        a
            color: #fff

#SE-intro
    padding: 4rem 0rem 4rem 0rem
    background-repeat: no-repeat
    background-size: cover
    padding-bottom: 50px
    margin-top: $space_from_previous_area_sm

    svg
        width: 5rem
        height: 5rem
        color: paletteColors.$main-yellow

    .column[name='title']
        color: paletteColors.$main-yellow
        font-size: 4rem
        font-weight: bold
    
    .column
        background-color: rgba(#000, 0.7)
        text-align: center
        p
            color: #fff
            font-size: 3rem
        
        &:nth-child(1)
            margin-bottom: 100px
        
        &:nth-child(2)
            width: 80%
            border-radius: 20px

#SE-areas
    margin-top: $space_from_previous_area / 2
    background-repeat: no-repeat
    background-size: cover
    padding: 50px 0

    .column
        margin-bottom: 50px

        img
            max-width: 90%

    .column:last-child
        margin-bottom: 0

#SE-funcionality
    margin-top: $space_from_previous_area / 2
    background-repeat: no-repeat
    background-size: cover
    background-position: 50% 10%
    padding: 50px 20px
    color: #fff

    h4
        font-size: 4rem

    p
        font-size: 2rem
        margin: 50px 0
        font-weight: 500
    
    #cards-wrapper
        display: flex
        justify-content: center
        flex-direction: column

        &>*
            width: 80%
            margin-left: auto
            margin-right: auto
            box-shadow: 0px 0px 2px 2px rgba(#000, 0.5)

        &>*:first-child
            margin-bottom: 50px

#SE-advantages
    margin-top: $space_from_previous_area_sm
    background-repeat: no-repeat
    background-size: cover
    padding: 50px 20px

    .title
        color: #fff
        background-color: #000
        max-width: 40%
        font-size: 4rem
        border-radius: 20px
        margin-bottom: 20px
    
#adv-wrapper
    background-repeat: no-repeat
    background-color: paletteColors.$main-blue
    width: 100%
    background-position: 98% 70%, 10% 20%, 70% 10%, 25% 100%
    background-size: 25% 35%

#paint-layer
    width: 100%
    height: 100%
    padding: 20px 50px
    background-color: rgba(paletteColors.$main-blue, 0.8)

#adv-grid
    .row
        margin-bottom: 50px

        .column
            display: flex
            justify-content: center

    .row:last-child
        margin-bottom: 0
    
    .adv-img
        box-shadow: 0px 0px 2px 2px rgba(#000, 0.5)
        border-radius: 10px

@media screen and (max-width:1000px)
    #SE-publi

        .inner
            font-size: 1.2rem

            strong
                font-size: 2.5rem
            
            .button
                font-size: 1rem
    
    #SE-intro
        margin-top: $space_from_previous_area_sm

@media screen and (max-width:867px)
    #SE-publi

        .inner
            font-size: 1rem

            strong
                font-size: 2.2rem
            
            .button
                font-size: 0.8rem

    #SE-intro

        .column[name='title']
            font-size: 2.6rem
        
        .column
            p
                font-size: 2rem
            
            &:nth-child(1)
                margin-bottom: 100px
            
            &:nth-child(2)
                width: 80%
                border-radius: 20px

    #paint-layer
        padding: 20px 10px

    #adv-grid
        .row
            .column
                max-width: 50%
                max-width: max-content
        
        .adv-img
            max-width: 95%
    #SE-funcionality

        h4
            font-size: 3rem

        p
            font-size: 1.5rem
            margin: 30px 0
        
        #cards-wrapper

            &>*
                width: 90%

            &>*:first-child
                margin-bottom: 50px
    
    #SE-advantages

        .title
            font-size: 3rem
            min-width: 100%
            margin-bottom: -20px
    
    #paint-layer
        padding: 20px 10px

    #adv-grid
        .row
            .column
                padding: 0
                margin-bottom: 0.5rem
        
        .adv-img
            max-width: 95%

@media screen and (min-width:800px)
    #SE-publi
        background-position-y: 50%
    
    #SE-intro
        background-position-y: 50%

@media screen and (min-width:1300px)
    #SE-publi
        background-position-y: 90%

@media screen and (max-width:580px)
    #SE-publi

        .inner
            width: fit-content
            max-width: 80%
            font-size: 1rem

            strong
                font-size: 2.2rem
            
            .button
                font-size: 0.8rem
    #SE-intro
        .column[name='title']
            font-size: 2.2rem
        
        .column
            p
                font-size: 1.8rem

@media screen and (max-width:470px)
    #SE-publi

        .inner
            width: fit-content
            font-size: 0.8rem

            strong
                font-size: 2rem
            
            .button
                font-size: 0.5rem
                padding: 0.8rem 0.6rem

    #adv-grid
        
        .adv-img
            max-width: 100%

@media screen and (max-width:400px)
    #SE-publi
        background-position-y: 10%

        .inner
            max-width: 100%
            font-size: 1rem

            strong
                font-size: 2rem
            
            .button
                font-size: 0.8rem

    #SE-areas
        margin-top: $space_from_previous_area_sm

    #SE-funcionality
        margin-top: $space_from_previous_area_sm

    #SE-advantages
        margin-top: $space_from_previous_area_sm


@media screen and (max-width:380px)
    #SE-funcionality
        p
            font-size: 1.5rem

        h4
            font-size: 2rem