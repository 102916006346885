@use '../../paletteColors'
@use '../../extra_fonts'

.copy-confirmation
    position: absolute
    z-index: 100
    background-color: paletteColors.$main-red
    max-width: 300px
    font-size: 1.5rem
    color: paletteColors.$main-white
    transition: 1s
    left: 50vw
    transform: translateX(-50%)
    padding: 10px 0
    font-weight: 600
    border-radius: 20px
    padding-inline: 1rem

.click-here-wrapper
    position: absolute
    z-index: 10
    width: 100%
    display: flex
    justify-content: center

    .click-here
        width: fit-content
        color: #fff
        background-color: #696969
        background-color: #424242
        margin-top: 1rem
        border-radius: 10px
        padding: 1rem 0.5rem
        font-weight: bold
        font-size: 1rem
        cursor: pointer

#container
    margin: 0
    margin-bottom: 50px

#content-area

    .container

        &>*:nth-of-type(2)
            margin: 0
            margin-block: 2rem

    #title-row
        margin: 0
        color: #fff
        background-color: paletteColors.$main-blue
        font-weight: bold
        font-size: 2rem
        text-align: center
    
    #number-row
        font-size: 1.8rem
        padding-block: 1rem
        color: #fff
        font-weight: bold
        justify-content: space-around

        .wrapper
            padding: 0
            box-shadow: 0px 0px 2px 2px rgba(#000, 0.3)
            border-radius: 10px

            .header
                border-top-left-radius: 10px
                border-top-right-radius: 10px

            .body
                padding-block: 1rem

                .inner-wrapper
                    padding: 0
                    margin-inline: auto
                    cursor: pointer

                    a
                        all: unset
                        all: inherit

                    .secondary-icon
                        color: #b7b7b7
                        font-size: 1.2rem
                
                .displayer
                    width: fit-content
                    padding: 0.5rem 0.6rem
                    border-radius: 10px
                    font-size: 1.2rem

                    &:first-child
                        margin-bottom: 10px
                    
                    .icon
                        margin-right: 5px
                        font-size: 2rem

            &:nth-of-type(1)// whatsapp
                .header
                    background-color: #20b438
                
                .body
                    
                    .displayer
                        background-color: #20b438
            
            &:nth-of-type(2)// numeros
                .header
                    background-color: #131313
                
                .body
                    
                    .displayer
                        background-color: #131313

    #form-row

        .wrapper
            box-shadow: 0px 0px 2px 2px rgba(#000, 0.5)
            max-width: 90%

            .header
                background-color: paletteColors.$main-blue
                color: #fff
                font-size: 2rem
                font-weight: bold
                padding-block: 0.5rem

            .body
                padding-left: 1rem
                padding-right: 5rem
                padding-bottom: 1rem

#map-area
    position: relative

@media screen and (max-width: 500px)
    #content-area
        #number-row
            font-size: 1.5rem
            padding-block: 1rem

            .wrapper

                .body
                    padding-block: 1rem

                    .inner-wrapper

                        .secondary-icon
                            display: none
                    
                    .displayer
                        padding: 0.5rem 0.6rem
                        font-size: 1rem
                    
                    .icon
                        margin-right: 5px
                        font-size: 2rem