@use '../../paletteColors'
@use '../../extra_fonts'

#debug-wrapper
    position: fixed
    z-index: 100
    top: 5vh
    left: 10vw
    padding: 10px 10px
    background-color: #000
    color: #fff
    font-size: 1.5rem
    font-weight: 500