@use '../../paletteColors'
@use '../../extra_fonts'

.carousel
    max-width: 100%

.carousel-inner
    max-width: 100%
    
.carousel-item
    img
        width: 100%