@use '../../paletteColors'

.card
    border: none
    max-width: 900px

.navbar-card.card
    background-color: paletteColors.$main-white
    color: paletteColors.$main-black

    &>*
        padding: 0
        margin: 0

    .card-title
        font-size: 2.5rem

    .card-subtitle
        font-size: 1rem
        color: paletteColors.$main-blue

.info-card.card

    .card-title
        color: paletteColors.$main-black
        font-size: 2rem
    
    .card-subtitle
        color: paletteColors.$main-red
        font-size: 1.3rem
        max-width: 70%

    &>*
        padding: 0
        margin: 0

.functionality-card
    padding: 20px 10px
    color: paletteColors.$main-black

    .icon
        font-size: 4rem
        margin-bottom: 20px

.carousel-card.card
    // box-shadow: 0px 0px 2px 2px rgba(#000, 0.2)
    margin-bottom: 50px
    max-width: 90%
    transition: .5s

    .card-title
        &>*:last-child
            margin-top: 10px
            margin-bottom: 0

    &:hover
        background-color: rgba(paletteColors.$main-blue, 0.9)
        color: paletteColors.$main-white

@media screen and (max-width: 800px)
    .card
        max-width: 700px