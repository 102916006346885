@use '../../paletteColors'
@use '../../extra_fonts'

#wp-fixed-icon
    color: paletteColors.$main-white
    position: fixed
    font-size: 2rem
    z-index: 100
    cursor: pointer
    top: 89vh
    top: 89svh
    transition-duration: 0.5s

    img
        max-height: 50px
    
    .text
        margin-left: 10px
    
    a
        text-decoration: none
        color: paletteColors.$main-white

.hidden
    visibility: hidden
    transition: visibility 0s, opacity 0.5s linear
    opacity: 0

.show
    visibility: visible
    opacity: 1

.minimized
    left: 94vw


@media screen and (max-width: 1084px)
    .minimized
        left: 93vw

@media screen and (max-width: 965px)

    .expanded
        left: 75vw

@media screen and (max-width: 930px)
    .minimized
        left: 92vw

@media screen and (max-width: 800px)
    .minimized
        left: 90vw

@media screen and (max-width: 490px)
    .minimized
        left: 88vw

@media screen and (max-width: 400px)
    .minimized
        left: 86vw

@media screen and (max-width: 360px)
    .minimized
        left: 84vw

@media screen and (max-width: 310px)
    .minimized
        left: 82vw
