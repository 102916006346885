@use '../../paletteColors'
@use '../../extra_fonts'

$footer-height: 50px

footer
    background-color: paletteColors.$main-blue

.small-only
    display: none!important

.footer
    padding: 20px 0
    color: paletteColors.$main-white
    font-weight: 600

    a
        color: paletteColors.$main-black
        color: paletteColors.$main-white
        text-decoration: none

        &:hover
            color: paletteColors.$main-yellow
            font-weight: 800
    
    .row
        .column
            padding: 10px 0

            &:first-child
                padding: 0
                padding-left: 20px

                a
                    margin-right: 11px
                
                label
                    font-size: 1.1rem
                    cursor: pointer
                    &:hover
                        color: paletteColors.$main-yellow
                    
                    &::after
                        display: none
                
                .img-wrapper
                    background-color: #fff
                    width: 100px
                    height: 100px
                    border-radius: 50px
    
                    img
                        max-height: 60px
    
    .icon
        &:first-child
            margin-right: 10px
        &:last-child
            margin-left: 10px
        &:hover
            color: paletteColors.$main-yellow

@media screen and (max-width: 760px)
    #toHome
        display: none
    
    .footer
        .row
            .column:first-child
                label
                    font-size: 1.5rem

                    &::after
                        content: "Engenharia do Sol"
                        font-size: 0.8rem
                        display: block
                        color: paletteColors.$main-yellow

@media screen and (max-width: 576px)
    .footer
        .row
            justify-content: center

            .column:first-child
                margin-bottom: 20px
                label
                    width: 100%
                    text-align: center

                    &::after
                        font-size: 1.5rem
                        display: inline-block
                        margin-left: 5px

@media screen and (max-width: 480px)
    .footer
        .row
            display: flex

            .column:first-child
                display: block
                font-size: 1rem
    
    .small-only
        display: flex!important

    .small-only:last-child
        width: 100%
        font-size: 1rem
    
    .normal-view
        display: none!important

@media screen and (max-width: 346px)
    .footer
        .row
            .column:first-child
                label
                    font-size: 1rem

                    &::after
                        font-size: 1rem