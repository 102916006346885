@use '../../paletteColors'
@use '../../extra_fonts'

#map-wrapper
    height: 90vh
    height: 100%
    max-width: 100%

.map-marker
    margin-top: -30px

.hidden
    visibility: hidden
    opacity: 0
    transition: visibility 0s, opacity 0.5s linear

.show
    visibility: visible
    justify-content: center
    opacity: 1

.alert
    font-size: 1.5rem