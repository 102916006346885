@use '../../paletteColors'
@use '../../extra_fonts'

.projects-container
    background-color: #fff

    .banner
        width: 100%
        background-repeat: no-repeat
        background-size: cover
        background-position-y: 60%
        color: paletteColors.$main-white
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4)
        padding: 8rem 0
        margin: 0

        h4
            font-size: 3rem
            font-weight: 600
    
    .decorator
        background-color: paletteColors.$main-blue
        height: 20px
        margin-bottom: 50px
    
    .carousel-indicators
        display: none

@media screen and (min-width: 1300px)
    .projects-container
        .banner
            background-position-y: 20%

@media screen and (max-width: 800px)
    .projects-container
        .banner
            padding-block: 6rem

            h4
                font-size: 2rem

@media screen and (max-width: 600px)
    .projects-container
        .banner
            padding-block: 4rem

@media screen and (max-width: 340px)
    .projects-container

        .banner
            
            h4
                font-size: 2rem
