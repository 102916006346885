@use '../../paletteColors'
@use '../../extra_fonts'

.project-info
    font-size: 0.8rem

    p
        margin-bottom: 0


@media screen and (max-width: 260px)    
    .project-info
        font-size: 0.7rem