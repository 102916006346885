@use '../../paletteColors'
@use '../../extra_fonts'

$navbar-height: 60px

nav
    background: linear-gradient(90deg, #ffce00 32%, rgba(239,169,45,1) 81%)
    background: #ffce00

.navbar-container
    padding-left: 0

.navbar-icon a img
    max-width: 200px

.navbar-nav
    width: 100%

    .nav-link
        font-size: 1.3rem
        font-weight: 600
        color: paletteColors.$main-black
        font-family: extra_fonts.$poppins
        margin: 0rem 0.5rem

        &:hover
            color: paletteColors.$main-white
            font-weight: bold

@media screen and (max-width: 991px)
    .navbar-icon a img
        max-width: 160px

    .navbar-nav
        a
            margin-left: 10px!important

@media screen and (max-heigth: 640px)
    .navbar-icon a img
        max-width: 160px