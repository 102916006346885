@use '../../paletteColors'
@use '../../extra_fonts'

#about-page
    background-color: #fff
    padding: 0
    padding-bottom: 50px

    #title
        background-repeat: no-repeat
        background-size: cover
        background-position-y: 60%
        padding: 10rem 0

        .column
            max-width: 50%
            background-color: rgba(black, 0.8)
            font-weight: bold
            border-radius: 10px
            padding: 2rem 0
        
            h4
                font-weight: bold
                color: paletteColors.$main-yellow
                font-size: 3rem
            
            p
                color: #fff
                font-size: 1.5rem
                margin: 0
    
    .contents
        margin: 0 auto
        margin-top: 100px
    
    #content-1
        max-width: 90%
        padding: 5rem 1rem
        border-left: 1rem solid #a8a6a6cc
        border-top-left-radius: 10px
        border-bottom-left-radius: 10px
        font-size: 3rem
        font-weight: 600
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    
    #content-2
        padding: 30rem 0rem 0rem 5rem
        background-repeat: no-repeat
        background-size: cover
        background-position-y: 10%
        box-shadow: 0px 0px 2px 2px rgba(#000, 0.3)
        font-size: 1.5rem
        font-weight: 600

        .wrapper
            box-shadow: 0px 0px 0px 2px rgba(#000, 0.3)
            display: flex
            background-color: paletteColors.$main-yellow
            border-top-left-radius: 10px
            padding: 1rem 1rem 1rem 2rem
    
#content-3
    background-repeat: no-repeat
    background-size: cover
    color: #fff
    font-size: 2rem
    padding: 8rem 0rem

    .column
        max-width: 80%
        background-color: rgba(black, 0.8)
        font-weight: bold
        border-radius: 10px
        padding: 2rem 1rem

@media screen and (max-width: 700px)
    #content-3
        font-size: 1.5rem

@media screen and (max-width: 600px)
    #about-page
    
        #content-1
            font-size: 2rem
        
        #content-2
            font-size: 1rem
            background-position-x: 10%
        
        #content-3
            font-size: 1rem

@media screen and (max-width: 500px)
    #about-page
        #title

            .column
            
                h4
                    font-size: 2rem
                
                p
                    font-size: 1rem
                    margin: 0
    
        #content-1
            font-size: 1.5rem
        
        #content-3
            background-position-x: 10%

@media screen and (max-width: 400px)
    #about-page
        #title

            .column
            
                h4
                    font-size: 1.5rem
                
                p
                    font-size: 0.8rem
    
        #content-1
            font-size: 1rem
            padding-bottom: 2rem
            padding-top: 2rem
        
        #content-3
            font-size: 1rem