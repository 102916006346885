@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500&family=Roboto+Mono:wght@400;500&family=Space+Mono:wght@400;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Oswald:wght@400;500;700&family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Ubuntu:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap')

// font-family: 'Kanit', sans-serif;
// font-family: 'Roboto Mono', monospace;
// font-family: 'Space Mono', monospace;

// font-family: 'Open Sans', sans-serif
// font-family: 'Oswald', sans-serif
// font-family: 'Poppins', sans-serif
// font-family: 'Roboto', sans-serif
// font-family: 'Ubuntu', sans-serif

$open-sans: 'Open Sans', sans-serif
$oswald: 'Oswald', sans-serif
$poppins: 'Poppins', sans-serif
$roboto: 'Roboto', sans-serif
$ubuntu: 'Ubuntu', sans-serif

$kanit:'Kanit', sans-serif
$roboto:'Roboto Mono', monospace
$space:'Space Mono', monospace