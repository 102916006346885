@use '../../paletteColors'
@use '../../extra_fonts'

$space_from_previous_area: 200px
$space_from_previous_area_sm: 80px

.color-yellow
    color: paletteColors.$main-yellow

.bold
    font-weight: bold

body
    overflow-x: hidden
    background-color: rgba(paletteColors.$main-yellow, 0.1)
    background-color: #fff

.App
    background-color: #fff

.alpha-layer
    background-color: rgba(#fff, 0.8)

.App #title
    background-color: paletteColors.$main-blue
    color: #fff
    width: 50%
    width: fit-content
    margin-left: 50%
    margin-top: $space_from_previous_area_sm
    transform: translateX(-50%)
    border-radius: 10px
    font-size: 3rem
    font-weight: bold

#jobs
    margin-top: $space_from_previous_area_sm

    .column
        margin-bottom: 20px
        cursor: pointer

        &:last-child
            margin-bottom: 0
    img
        max-width: 50%
        border-top-right-radius: 10px
        border-bottom-right-radius: 10px

#jobs-detail
    margin: 0
    margin-top: $space_from_previous_area
    margin-bottom: 50px
    justify-content: space-around
    padding-block: 2rem

    .detail
        margin-bottom: 100px
        padding: 0
        color: #fff
        border-bottom-right-radius: 20px
        border-bottom-left-radius: 20px
        overflow: hidden

        &:last-child
            margin-bottom: 0

        .color
            padding-block: 1rem
            border-top-left-radius: 20px
            border-top-right-radius: 20px
        
            .title
                font-weight: bold
                font-size: 3rem
                margin-block: 0
                text-align: center
            
        .content

            background-repeat: no-repeat
            background-size: cover

            p
                font-size: 1.5rem
                font-weight: 500

            .wrapper
                padding-block: 2rem
                padding-left: 1rem
                background-color: rgba(#000, 0.5)

            ul
                font-weight: 500
                font-size: 1.2rem

   
        &:nth-of-type(1)
            .color
                background-color: paletteColors.$main-orange
        
        &:nth-of-type(2)
            .color
                background-color: paletteColors.$main-red
        
        &:nth-of-type(3)
            .color
                background-color: paletteColors.$main-green
        
        &:nth-of-type(4)
            .color
                background-color: paletteColors.$main-blue
        
        &:nth-of-type(5)
            .color
                background-color: paletteColors.$main-black


@media screen and (min-width:1700px)
    #jobs-detail

        .detail
    
            &:nth-of-type(1)
                .content
                    .wrapper
                        padding-block: 4rem
            
            &:nth-of-type(3)
                .content
                    .wrapper
                        padding-block: 4rem

@media screen and (min-width:1000px)
    #jobs-detail
        .detail
            &:nth-of-type(1)
                .content
                    background-position-y: 20%

@media screen and (max-width:974px)
    #jobs
        img
            max-width: 70%
    
    #jobs-detail
        .detail
            .color
                .title
                    font-size: 2.5rem
            
            .content
                p
                    font-size: 1.5rem

    #SE-advantages
        padding: 50px 0px

        .title
            color: #fff
            background-color: #000
            font-size: 4rem
            border-radius: 20px
            margin-bottom: 20px
            min-width: 80%

@media screen and (max-width:560px)
    .App #title
        width: 70%
        margin-left: 50%
        transform: translateX(-50%)
        font-size: 3rem
        font-weight: bold
    
    #jobs-detail
        .detail
            .color
                .title
                    font-size: 2rem
            
            .content
                p
                    font-size: 1.2rem

@media screen and (max-width:450px)
    #jobs-detail
        .detail
            .color
                .title
                    font-size: 1.8rem
            
            .content
                p
                    font-size: 1rem
                
                ul
                    font-size: 0.8rem

@media screen and (max-width:400px)
    .App
        #title
            p
                font-size: 2rem
